var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "mt-2" },
    [
      _c(
        "masthead",
        { attrs: { "show-search": false } },
        [
          _c(
            "b-button",
            {
              class: _vm.$mq != "sm" ? "btn-shadow mr-2" : "",
              attrs: { variant: "link" },
              on: { click: _vm.goBack },
            },
            [
              _c("feather-icon", { attrs: { type: "chevron-left" } }),
              _vm._v("\n      Back\n    "),
            ],
            1
          ),
        ],
        1
      ),
      _c("h2", { staticClass: "mb-0" }, [_vm._v("\n    Forms Library\n  ")]),
      _vm.loaded && _vm.filings.length > 0
        ? _c(
            "b-container",
            [
              _vm.domestic.length > 0
                ? _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("h4", { staticClass: "mb-1" }, [
                          _vm._v("\n          Domestic Filings\n        "),
                        ]),
                        _c("p", [
                          _vm._v(
                            "\n          You want to form " +
                              _vm._s(_vm.companyName) +
                              " in " +
                              _vm._s(_vm.jurisdictionName) +
                              " or you've already registered " +
                              _vm._s(_vm.companyName) +
                              " in " +
                              _vm._s(_vm.jurisdictionName) +
                              ".\n        "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.domestic.length > 0
                ? _c(
                    "b-list-group",
                    { staticClass: "list-group" },
                    _vm._l(_vm.domestic, function (item) {
                      return _c(
                        "b-list-group-item",
                        { key: item.id },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "link" },
                              on: {
                                click: function ($event) {
                                  return _vm.view(item)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(item.description) +
                                  "\n        "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _vm._e(),
              _vm.miscellaneous.length > 0
                ? _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("h4", { staticClass: "mb-1" }, [
                          _vm._v("\n          Miscellaneous Filings\n        "),
                        ]),
                        _c("p", [
                          _vm._v(
                            "\n          You want to form " +
                              _vm._s(_vm.companyName) +
                              " in " +
                              _vm._s(_vm.jurisdictionName) +
                              " or you've already registered " +
                              _vm._s(_vm.companyName) +
                              " in " +
                              _vm._s(_vm.jurisdictionName) +
                              ".\n        "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.foreign.length > 0
                ? _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("h4", { staticClass: "mb-1" }, [
                          _vm._v("\n          Foreign Filings\n        "),
                        ]),
                        _c("p", [
                          _vm._v(
                            "\n          You want to register " +
                              _vm._s(_vm.companyName) +
                              " in " +
                              _vm._s(_vm.jurisdictionName) +
                              " or you've already registered " +
                              _vm._s(_vm.companyName) +
                              " in " +
                              _vm._s(_vm.jurisdictionName) +
                              ".\n        "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.foreign.length > 0
                ? _c(
                    "b-list-group",
                    { staticClass: "list-group mb-4" },
                    _vm._l(_vm.foreign, function (item) {
                      return _c(
                        "b-list-group-item",
                        { key: item.id },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "link" },
                              on: {
                                click: function ($event) {
                                  return _vm.view(item)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(item.description) +
                                  "\n        "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _vm._e(),
              _vm.miscellaneous.length > 0
                ? _c(
                    "b-list-group",
                    { staticClass: "list-group mb-4" },
                    _vm._l(_vm.miscellaneous, function (item) {
                      return _c(
                        "b-list-group-item",
                        { key: item.id },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "link" },
                              on: {
                                click: function ($event) {
                                  return _vm.view(item)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(item.description) +
                                  "\n        "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _c("b-container", [
            _c("h3", [
              _vm._v(
                "\n      We have no filing resources for your company. If you need help please contact us.\n    "
              ),
            ]),
          ]),
      !_vm.loaded
        ? _c(
            "b-row",
            { staticClass: "d-flex justify-content-center mb-3" },
            [
              _c("ct-centered-spinner", {
                attrs: { "data-cy": "busyIndicator" },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }