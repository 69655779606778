<template>
  <!--  TODO Not currently in use. Before using this component, integrate it with the Store and
      make it aware of contextual checkoutDomesticJurisdiction in updated HireUs flow. -->
  <b-container class="mt-2">
    <masthead :show-search="false">
      <b-button
        variant="link"
        :class="$mq != 'sm' ? 'btn-shadow mr-2' : ''"
        @click="goBack"
      >
        <feather-icon type="chevron-left" />
        Back
      </b-button>
    </masthead>
    <h2 class="mb-0">
      Forms Library
    </h2>
    <b-container v-if="loaded && filings.length > 0">
      <b-row v-if="domestic.length > 0">
        <b-col>
          <h4 class="mb-1">
            Domestic Filings
          </h4>
          <p>
            You want to form {{ companyName }} in {{ jurisdictionName }} or you've already registered {{ companyName }} in {{ jurisdictionName }}.
          </p>
        </b-col>
      </b-row>
      <b-list-group v-if="domestic.length > 0" class="list-group">
        <b-list-group-item v-for="item in domestic" :key="item.id">
          <b-button variant="link" @click="view(item)">
            {{ item.description }}
          </b-button>
        </b-list-group-item>
      </b-list-group>

      <b-row v-if="miscellaneous.length > 0">
        <b-col>
          <h4 class="mb-1">
            Miscellaneous Filings
          </h4>
          <p>
            You want to form {{ companyName }} in {{ jurisdictionName }} or you've already registered {{ companyName }} in {{ jurisdictionName }}.
          </p>
        </b-col>
      </b-row>

      <b-row v-if="foreign.length > 0">
        <b-col>
          <h4 class="mb-1">
            Foreign Filings
          </h4>
          <p>
            You want to register {{ companyName }} in {{ jurisdictionName }} or you've already registered {{ companyName }} in {{ jurisdictionName }}.
          </p>
        </b-col>
      </b-row>
      <b-list-group v-if="foreign.length > 0" class="list-group mb-4">
        <b-list-group-item v-for="item in foreign" :key="item.id">
          <b-button variant="link" @click="view(item)">
            {{ item.description }}
          </b-button>
        </b-list-group-item>
      </b-list-group>
      <b-list-group v-if="miscellaneous.length > 0" class="list-group mb-4">
        <b-list-group-item v-for="item in miscellaneous" :key="item.id">
          <b-button variant="link" @click="view(item)">
            {{ item.description }}
          </b-button>
        </b-list-group-item>
      </b-list-group>
    </b-container>
    <b-container v-else>
      <h3>
        We have no filing resources for your company. If you need help please contact us.
      </h3>
    </b-container>
    <b-row v-if="!loaded" class="d-flex justify-content-center mb-3">
      <ct-centered-spinner
        data-cy="busyIndicator"
      />
    </b-row>
  </b-container>
</template>

<script>

// TODO Not currently in use. Before using this component, integrate it with the Store and
// make it aware of contextual checkoutDomesticJurisdiction in updated HireUs flow.

import { mapGetters } from 'vuex'
import Masthead from '../../components/shared/Masthead'
import FeatherIcon from '../../components/shared/FeatherIcon'
import CtCenteredSpinner from '../../components/shared/CtCenteredSpinner'

export default {
  name: 'FilingSelection',
  components: {
    FeatherIcon,
    Masthead,
    CtCenteredSpinner,
  },
  props: {
    domestic: {
      type: Array,
      default: () => [],
    },
    foreign: {
      type: Array,
      default: () => [],
    },
    miscellaneous: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loaded: false,
      filings: null,
      companyId: this.$route.params.companyId,
      companyName: this.$route.params.companyName,
      jurisdictionId: this.$route.params.jurisdictionId,
    }
  },
  computed: {
    ...mapGetters({
      jurisdictions: 'jurisdictions/getAll',
    }),
    jurisdictionName() {
      if (!this.loaded) {
        return null
      }
      const jurisdictionId = this.jurisdictionId
      return this.jurisdictions.filter(j => j.id === jurisdictionId)[0].stateProvinceRegion
    },
  },
  beforeCreate() {
    this.companies = this.$store.getters['companies/getAll']
  },
  async mounted() {
    this.filings = await this.selectFilings()

    this.selectMiscellaneousFilings()
    this.selectForeignFilings()
    this.selectDomesticFilings()

    this.loaded = true
  },
  methods: {
    async selectFilings() {
      this.response = await this.$store.dispatch('companies/fetchCompanyFilings', {
        params: {
          jurisdiction: this.jurisdictionId,
          company: this.companyId,
        },
      })
      // eslint-disable-next-line no-undef
      return _.sortBy(this.response, 'name').find(a => !!a)
    },
    async selectDomesticFilings() {
      this.filings.forEach(item => {
        if (item.locality === 'domestic' || item.locality === 'both') {
          this.domestic.push(item)
        }
      })
    },
    async selectForeignFilings() {
      this.filings.forEach(item => {
        if (item.locality === 'foreign' || item.locality === 'both') {
          this.foreign.push(item)
        }
      })
    },
    selectMiscellaneousFilings() {
      this.filings.forEach(item => {
        if (
          item.locality !== 'foreign' &&
          item.locality !== 'both' &&
          item.locality !== 'domestic'
        ) {
          this.miscellaneous.push(item)
        }
      })
    },
    async view(filing) {
      this.$router.push({
        name: 'filingMethodSelection',
        params: {
          companyId: this.companyId,
          jurisdictionId: this.jurisdictionId,
          filingId: filing.id,
        },
      })
    },
    goBack() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss" scoped></style>
